import React, { useContext } from 'react';
import { translateData } from 'utils';
import { LanguageContext } from 'context';
import { Link } from 'gatsby';

const FooterBottomPanel = () => {
  const currentLang = useContext(LanguageContext).currentLang;
  const dictionary = useContext(LanguageContext).staticData;
  const linksInfo = translateData(dictionary);

  return (
    <div className='footer-btm-bar-wrapper'>
      <div className='container'>
        <div className='copyright-text'> ©2024 {linksInfo['all-rights-reserved']}</div>

        <nav>
          <ul className='site-links'>
            {/* <li>©2023 {linksInfo['all-rights-reserved']}</li> */}
            <li>
              <Link
                title={linksInfo['link-privacy-policy']}
                to={`${currentLang === 'ar-SA' ? '/privacy-policy' : '/en/privacy-policy'}`}
              >
                {linksInfo['link-privacy-policy']}
              </Link>
            </li>
            <li>
              <Link
                title={linksInfo['link-terms-conditions']}
                to={`${currentLang === 'ar-SA' ? '/terms-and-conditions' : '/en/terms-and-conditions'}`}
              >
                {linksInfo['link-terms-conditions']}
              </Link>
            </li>
            {/* <li>
              <Link
                title={linksInfo['link-vendor-registration']}
                to={`${currentLang === 'ar-SA' ? '/vendor-registration' : '/en/vendor-registration'}`}
              >
                {linksInfo['link-vendor-registration']}
              </Link>
            </li> */}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default FooterBottomPanel;
